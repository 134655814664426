import { ReactComponent as FacebookIcon } from "../lib/icons/Facebook.svg";
import { ReactComponent as TumblrIcon } from "../lib/icons/Tumblr.svg";
import { ReactComponent as TwitterIcon } from "../lib/icons/Twitter.svg";
import { GrLinkedin } from "react-icons/gr";
import { FaGithub } from "react-icons/fa";


export const social4 = [
  {
    socialIcon: (
      <FaGithub className="h-5 w-5 lg:h-10 lg:w-10 fill-[#3d5a70] hover:fill-accent"></FaGithub>
    ),
    socialLink: "https://github.com/MickeMarten",
    socialTitle: "Github",
  },
  {
    socialIcon: (
      <GrLinkedin className="h-5 w-5 lg:h-10 lg:w-10 fill-[#3d5a70] hover:fill-accent"></GrLinkedin >
    ),
    socialLink: "https://www.linkedin.com/in/mikael-m%C3%A5rtensson-49b041238/",
    socialTitle: "Linkedin",
  },

];
