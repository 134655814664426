import PatternImg from '../../assets/images/patternImg.jpg';
import PatternImg2 from '../../assets/images/patternImg2.jpg';
import AboutUsBg from '../../assets/images/secBg.jpg';
import { SectionTitle } from '../SectionTitles';
import AboutImg from '../../assets/images/resources/aboutImg.jpg';
import { EducationAndSkills } from '../EducationAndSkills';
import { ReactComponent as Signature } from '../../lib/icons/Signature.svg';
import { GoPrimitiveDot } from 'react-icons/go';

const AboutUs = (props) => {
  return (
    <section className='aboutUsWrap py-[4.5rem] md:py-[5.5rem] lg:py-[6.5rem] xl:py-[7.5rem] relative w-full'>
      <div
        className='fixedBg bg-left-top bg-no-repeat opacity-20'
        style={{ backgroundImage: `url(${PatternImg})` }}
      ></div>
      <div className='container mx-auto'>
        <SectionTitle
          title='Education & Experience'
          //titleInner="Education & Experience"
          // desc="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore."
        ></SectionTitle>
        <div className='aboutUs relative w-full p-[1.25rem] lg:p-[1.875rem] mt-[5.9375rem]'>
          <div className='aboutUsBg before:absolute before:inset-0 before:rounded-[10px] before:sm:rounded-[1.25rem] before:md:rounded-[3.125rem] before:opacity-70 before:z-[1] bg-dark2 bg-blend-multiply absolute rounded-[10px] sm:rounded-[1.25rem] md:rounded-[3.125rem] inset-0 left-0 lg:left-[10.9375rem] bg-no-repeat bg-center bg-cover sm:w-3/3 lg:w-2/3 '></div>
          <div className='grid gap-[1.875rem] lg:grid-cols-2 grid-cols-1'>
            {/* Experience Section */}
            <div>
              <div className='aboutCapWrap w-full shadow-2xl'>
                <div className='aboutCap relative rounded-[10px] sm:rounded-[1.25rem] md:rounded-[3.125rem] overflow-hidden z-[1] lg:py-12 xl:pt-[4.6875rem] xl:pb-[4.375rem] p-5 md:p-10 lg:px-[3.5rem] xl:px-20'>
                  <div
                    className='aboutCapBg bg-twitter opacity-90 absolute rounded-[10px] sm:rounded-[1.25rem] md:rounded-[3.125rem] inset-0 bg-no-repeat bg-center bg-cover z-[-1]'
                    style={{ backgroundImage: `url(${PatternImg2})` }}
                  ></div>
                  <h3 className='text-accent2 font-light text-[1.5rem] md:text-[1.875rem] leading-tight font-Poppins'>
                    Experience
                  </h3>
                  <div className='h-80'>
                    <ul className='grid grid-cols-1 gap-6 mt-6'>
                      <li className='grid grid-cols-1 gap-y-1 text-[1rem] md:text-[1.125rem]'>
                        <strong className='text-desc3 uppercase font-bold font-Poppins'>Pubq</strong>
                        <p className='text-desc3 font-semibold font-NunitoSans'>2024-05-20 - 2024-08-20</p>
                        <span className='text-desc3 font-semibold font-NunitoSans'>FrontEnd Developer, IT-Support</span>
                        <span className='text-desc3 font-semibold font-NunitoSans'>
                          React, Angular,Ionic Firebase,{' '}
                        </span>
                      </li>
                      <li className='grid grid-cols-1 gap-y-1 text-[1rem] md:text-[1.125rem]'>
                        <strong className='text-desc3 uppercase font-bold font-Poppins'>Yrkesgymnasiet</strong>
                        <p className='text-desc3 font-semibold font-NunitoSans'>2022-2023</p>
                        <span className='text-desc3 font-semibold font-NunitoSans'>Physical Education Teacher</span>
                      </li>
                      <li className='grid grid-cols-1 gap-y-1 text-[1rem] md:text-[1.125rem]'>
                        <strong className='text-desc3 uppercase font-bold font-Poppins'>Sandbyängsskolan</strong>
                        <p className='text-desc3 font-semibold font-NunitoSans'>2015-2022</p>
                        <span className='text-desc3 font-semibold font-NunitoSans'>Physical Education Teacher</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            {/* Education Section */}
            <div>
              <div className='aboutCapWrap w-full shadow-2xl '>
                <div className='aboutCap relative rounded-[10px] sm:rounded-[1.25rem] md:rounded-[3.125rem] overflow-hidden z-[1] lg:py-12 xl:pt-[4.6875rem] xl:pb-[4.375rem] p-5 md:p-10 lg:px-[3.5rem] xl:px-20'>
                  <div
                    className=' aboutCapBg bg-twitter opacity-90 absolute rounded-[10px] sm:rounded-[1.25rem] md:rounded-[3.125rem] inset-0 bg-no-repeat bg-center bg-cover z-[-1]'
                    style={{ backgroundImage: `url(${PatternImg2})` }}
                  ></div>
                  <h3 className='text-accent3 font-light text-[1.5rem] md:text-[1.875rem] leading-tight font-Poppins'>
                    Education
                  </h3>
                  <div className='h-80'>
                    <ul className='grid grid-cols-1 gap-6 mt-6'>
                      <li className='grid grid-cols-1 gap-y-1 text-[1rem] md:text-[1.125rem]'>
                        <strong className='text-desc3 uppercase font-bold font-Poppins'>Lernia Yrkeshögskola</strong>
                        <p className='text-desc3 font-semibold font-NunitoSans'>2023-2025</p>
                        <span className='text-desc3 font-semibold font-NunitoSans'>
                          System developer Java & JavaScript
                        </span>
                      </li>
                      <li className='grid grid-cols-1 gap-y-1 text-[1rem] md:text-[1.125rem]'>
                        <strong className='text-desc3 uppercase font-bold font-Poppins'>Linköpings Universitet</strong>
                        <p className='text-desc3 font-semibold font-NunitoSans'>2012-2015</p>
                        <span className='text-desc3 font-semibold font-NunitoSans'>Physical Education Teacher</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutUs;
