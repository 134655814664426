import { ReactComponent as FacebookIcon } from "../lib/icons/Facebook.svg";
import { ReactComponent as TumblrIcon } from "../lib/icons/Tumblr.svg";
import { ReactComponent as TwitterIcon } from "../lib/icons/Twitter.svg";
import { GrLinkedin } from "react-icons/gr";
import { FaGithub } from "react-icons/fa";

export const social = [
  {
    socialIcon: <FaGithub  className="h-7 w-7 md:h-10 md:w-10 fill-white hover:fill-accent2"></FaGithub >,
    socialLink: "https://github.com/MickeMarten",
    socialTitle: "Github"
  },
  {
    socialIcon: <GrLinkedin className="h-7 w-7 md:h-10 md:w-10 fill-white hover:fill-accent2"></GrLinkedin>,
    socialLink: "https://www.linkedin.com/in/mikael-m%C3%A5rtensson-49b041238/",
    socialTitle: "Linkedin"
  },
  
];
