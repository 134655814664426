import { ReactComponent as HomeIcon } from '../lib/icons/Home.svg';
import { ReactComponent as EnvelopeIcon } from '../lib/icons/Envelope.svg';
import { ReactComponent as PhoneIcon } from '../lib/icons/Phone.svg';

export const conInfo = [
  // {
  //   conIcon: <HomeIcon className="h-[3.4375rem] fill-white"></HomeIcon>,
  //   conTitle: "Norrköping:",
  //   conCap: "Butgatan 16",
  // },
  {
    conIcon: <PhoneIcon className='h-[2.5rem] fill-white'></PhoneIcon>,
    conTitle: 'Phone:',
    conCap: '+46739919218',
  },
  {
    conIcon: <EnvelopeIcon className='h-[2.5rem] fill-white'></EnvelopeIcon>,
    conTitle: 'Email Address:',
    conCap: 'KarlMikael.Martensson@gmail.com',
  },
];
